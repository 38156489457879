export const environment = {
  production: true,

  version: '2024.7.0',
  versionHash: '7c3739ca01b37be2d497c659ecaca2a75d38cd1a',

  api: {
    basePath: 'https://api.sugardaddy-stage.synapse-intra.com',
  },

  stream: {
    basePath: 'wss://api.sugardaddy-stage.synapse-intra.com',
  },

  cms: {
    basePath: 'https://help.sugardaddy-stage.synapse-intra.com',
  },

  site: {
    noindex: 'yes',
  },

  payment: {
    usePaymentServiceMock: false,
    paidy: {
      apiKey: 'pk_test_edplhs06uv2ahi2l6v4odru8sh',
    }
  },

  amplify: {
    Auth: {
      identityPoolId: '',
      region: 'ap-northeast-1',
      userPoolId: 'ap-northeast-1_y6adhaewC',
      userPoolWebClientId: '6entavsh8g4ug6uhfj79e0c1cu',
    },
  },

  recatpcha: {
    siteKey: '6LcAuVojAAAAANRkBpGBKQJecP7EIjtPMeMuR0sb',
  },

  analysis: {
    gtagId: 'G-LH216B2FW9',
    gtagId2: 'GTM-K6KF47NZ',
    gtm_auth: 'dAPDoqqmBAXs8HdNSQGJlg',
    gtm_preview: 'env-3',
    gtm_resource_path: '',
    pixelId: '1976549862601304',
    uwtId: 'o6019',
  },
};

export const ENV_MODULES: any[] = [];

export const ENV_PROVIDERS: any[] = [];
